import React from 'react';
import classnames from 'classnames';

import {trackEvent, events} from '../../tracking';
import {GeoCountry} from '../../api';
import {SocialButtons} from '../SocialButtons';
import {jumpToPreviews} from '../IndexPagePreviews';

import styles from './styles.css';

export interface MenuBarProps {
  isHome?: boolean;
  onSelectGeo: (geo: GeoCountry) => void;
  onAbout: () => void;
  selectedGeo: GeoCountry;
  firstGeo?: GeoCountry;
  desktopInMobileView?: boolean;
}

const GEO_LIST: GeoCountry[] = ['gb', 'us'];

export const MenuBar = ({
  isHome = false,
  onSelectGeo,
  onAbout,
  selectedGeo,
  firstGeo = 'gb',
  desktopInMobileView
}: MenuBarProps) => {
  const orderedGeos = GEO_LIST.slice().sort((geo) => (geo === firstGeo ? -1 : 1));
  const handleSelectFlag = (geo: GeoCountry) => {
    onSelectGeo(geo);
    trackEvent(events.FLAG_SELECT, {geo});
  };

  return (
    <div className={styles.menuBar}>
      <SocialButtons sourceGeo={selectedGeo} mode="inline" />
      <div className={styles.selectGeoGroup}>
        {orderedGeos.map((geo) => (
          <button
            aria-label={geo}
            className={classnames(styles.selectGeo, styles[geo], selectedGeo === geo && styles.selectedGeo)}
            key={geo}
            onClick={() => handleSelectFlag(geo)}
          >
            <i className={styles.flag} />
          </button>
        ))}
      </div>
      <div className={styles.linkButtonGroup}>
        {isHome && !desktopInMobileView && (
          <button className={classnames(styles.linkButton, styles.liveHomepagesButton)} onClick={jumpToPreviews}>
            <strong className={styles.liveHomepageLiveWord}>LIVE</strong> Homepages
          </button>
        )}
        {!isHome && (
          <a className={styles.linkButton} href="/">
            Top Stories
          </a>
        )}
        <button className={styles.linkButton} onClick={onAbout}>
          About
        </button>
      </div>
    </div>
  );
};
