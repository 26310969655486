import React from 'react';

import {isMobileDevice} from '../../utils';

import styles from './styles.css';
import {IconMobile} from './IconMobile';
import {IconDesktop} from './IconDesktop';

interface ViewToggleBoxProps {
  onDesktopView: () => void;
  onMobileView: () => void;
  desktopView: boolean;
}

export const ViewToggleBox = ({onDesktopView, onMobileView, desktopView}: ViewToggleBoxProps) => {
  if (!isMobileDevice()) {
    return null;
  }

  return (
    <button
      aria-label="toggle view"
      className={styles.viewToggleBox}
      onClick={desktopView ? onMobileView : onDesktopView}
    >
      {!desktopView && (
        <div className={styles.boxContentWrapper}>
          <IconDesktop className={styles.iconDesktop} />
          <span>Desktop view</span>
        </div>
      )}
      {desktopView && (
        <div className={styles.boxContentWrapper}>
          <IconMobile className={styles.iconMobile} />
          <span>Mobile view</span>
        </div>
      )}
    </button>
  );
};

Object.defineProperty(ViewToggleBox, 'name', {value: 'ViewToggleBox'});
