import React, {CSSProperties} from 'react';

export const IconDesktop = ({className, style}: {className?: string; style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    className={className}
    style={style}
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M19.717 0H.283A.284.284 0 0 0 0 .283v10.834c0 .155.128.283.283.283H8.6v2.8h2.8v-2.8h8.317a.284.284 0 0 0 .283-.283V.283A.284.284 0 0 0 19.717 0zM14 14.6V16H6v-1.4h8zM19.2.8v9.8H.8V.8h18.4z"
    />
  </svg>
);
