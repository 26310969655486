import React, {useEffect, useState, useRef} from 'react';
import classnames from 'classnames';
import URL from 'url-parse';

import {trackEvent, events} from '../../tracking';
import {GeoCountry, TopStoriesCategory, getCategoryKeyByTabAndGeo} from '../../api';
import {topArticles, Result} from '../../api/search';
import {setExternalAppState} from '../../api/externalAppState';
import {isMobileDevice} from '../../utils';
import {ResultItem} from '../ResultItem';
import {Spinner} from '../Spinner';
import {LayoutBreakpoint} from '../IndexPagePreviews/types';

import styles from './styles.css';
import {LessButton} from './LessButton';
import {MoreButton} from './MoreButton';

const RESULTS_PER_PAGE = 20;

interface CategoryDisplay {
  label: string;
  value: TopStoriesCategory;
  geo?: GeoCountry;
}

const categories: CategoryDisplay[] = [
  {label: 'Top Stories', value: 'news'},
  {label: 'Showbiz', value: 'entertainment'},
  {label: 'Sport', value: 'sport'}
];

interface TopStoriesProps {
  onPageChange?: (newPage: number) => void;
  onTabChange?: (newTab: TopStoriesCategory) => void;
  onMoreLikeThis?: (story: Result) => void;
  sourceGeo?: GeoCountry;
  showTabs?: boolean;
  refreshStamp?: number | null;
  debug?: boolean;
  startTab?: TopStoriesCategory;
  layoutBreakpoint?: LayoutBreakpoint;
  desktopInMobileView?: boolean;
}

export const TopStories = ({
  onPageChange,
  onTabChange,
  onMoreLikeThis,
  sourceGeo = 'gb',
  showTabs = true,
  refreshStamp = null,
  debug = false,
  startTab,
  desktopInMobileView
}: TopStoriesProps) => {
  const [results, setResults] = useState<Result[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTabState] = useState<TopStoriesCategory>(startTab || 'news');
  const [openedMoreId, setOpenedMoreId] = useState<string | null>(null);
  const [page, setPageState] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const previousState = useRef<{currentTab: TopStoriesCategory; sourceGeo: GeoCountry} | undefined>();
  const setPage = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
    setPageState(newPage);
  };
  const setCurrentTab = (newTab: TopStoriesCategory) => {
    if (onTabChange) {
      onTabChange(newTab);
    }
    setCurrentTabState(newTab);
  };
  const requestStories = ({
    tab,
    geo,
    previousTabKey
  }: {
    tab: TopStoriesCategory;
    geo: GeoCountry;
    previousTabKey?: string;
  }) => {
    let aborted = false;
    (async () => {
      try {
        const tabKey = getCategoryKeyByTabAndGeo(tab, geo);
        // Commenting this out to debug RTA anomaly
        // const isAutoRefresh = tabKey === previousTabKey;
        const isAutoRefresh = false;

        setExternalAppState('home', {
          geo,
          tab
        });

        const response = await topArticles(tabKey);

        if (isAutoRefresh) {
          trackEvent(events.TOP_STORIES_AUTOREFRESH, {channel: tabKey});
        } else {
          trackEvent(events.TAB_VIEW_EVENT, {
            autoRefresh: tabKey === previousTabKey ? 1 : 0,
            channel: tabKey,
            geo,
            previousTabKey,
            tab
          });
        }

        if (!aborted) {
          setResults(response && response.results);
          setLoading(false);
        }
      } catch (error) {
        if (!aborted) {
          setError(error);
        }
      }
    })().catch();

    return () => {
      aborted = true;
    };
  };

  useEffect(() => {
    setLoading(true);
    setOpenedMoreId(null);
    setPage(0);

    const previousTabKey =
      previousState.current &&
      getCategoryKeyByTabAndGeo(previousState.current.currentTab, previousState.current.sourceGeo);
    const abort = requestStories({tab: currentTab, geo: sourceGeo, previousTabKey});

    previousState.current = {currentTab, sourceGeo};

    return () => {
      abort();
    };
  }, [currentTab, sourceGeo, refreshStamp]);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const target = event.target as HTMLElement;

      if (
        target &&
        containerRef.current &&
        openedMoreId &&
        !containerRef.current.contains(target) &&
        !target.closest(`.${styles.relatedButton}`)
      ) {
        setOpenedMoreId(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    if (openedMoreId && !desktopInMobileView && isMobileDevice() && containerRef.current) {
      const openedElement = containerRef.current.querySelector('[data-is-opened="true"]');

      if (openedElement) {
        openedElement.scrollIntoView();
      }
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [openedMoreId]);

  if (!results) {
    return (
      <div className={styles.container}>
        {error && <div className={styles.error}>Error fetching Top Stories</div>}
        {!error && loading && <Spinner />}
      </div>
    );
  }

  const maxPages = Math.floor(results.length / RESULTS_PER_PAGE);
  const resultsInPage = results.slice(0, (page + 1) * RESULTS_PER_PAGE);
  const scrollToTop = () => {
    if (headingRef.current) {
      window.scrollTo(0, headingRef.current.offsetTop - 40);
    }
  };
  const handleClose = () => {
    setPage(0);
    trackEvent(events.TOP_STORIES_PAGINATION_LESS);
    requestAnimationFrame(() => {
      const target = containerRef.current && containerRef.current.children[containerRef.current.children.length - 1];

      if (target) {
        target.scrollIntoView();
      } else {
        scrollToTop();
      }
    });
  };
  const handleNextPage = () => {
    setPage(page + 1);
    trackEvent(events.TOP_STORIES_PAGINATION_MORE);
  };
  const handleToggleRelated = (url: string, position: number) => {
    const isOpened = openedMoreId && openedMoreId === url;

    setOpenedMoreId(isOpened ? null : url);

    if (isOpened) {
      trackEvent(events.TOP_STORIES_RELATED_CLOSE, {url, position});
    } else {
      trackEvent(events.TOP_STORIES_RELATED_OPEN, {url, position});
    }
  };
  const handleResultTracking = (
    eventName: string,
    {id, url, title}: Result,
    subchannel: string,
    position: number,
    relatedPosition?: number
  ) => {
    const topStoriesKey = getCategoryKeyByTabAndGeo(currentTab, sourceGeo);

    trackEvent(eventName, {
      channel: new URL(url).host,
      id,
      position,
      relatedPosition,
      subchannel,
      title,
      topStoriesKey,
      url
    });
  };

  const activeCategory = categories.find(({value}) => value === currentTab);

  if (activeCategory && activeCategory.geo && activeCategory.geo !== sourceGeo) {
    setCurrentTab('news');
  }

  return (
    <div className={classnames(styles.container, desktopInMobileView && styles.desktopInMobileView)} data-page={page}>
      {showTabs && (
        <div className={classnames(styles.heading)} ref={headingRef}>
          <div className={styles.buttonSelectors} data-current-tab={currentTab}>
            {categories
              .filter(({geo}) => !geo || geo === sourceGeo)
              .map(({label, value}) => (
                <button
                  className={classnames(styles.tabSelector, currentTab === value && styles.selectedTab)}
                  data-tab-name={value}
                  disabled={loading}
                  key={value}
                  onClick={() => setCurrentTab(value)}
                >
                  {label}
                </button>
              ))}
          </div>
        </div>
      )}
      {!showTabs && <div className={styles.noTabsHeading}>TOP STORIES</div>}
      <div className={classnames(styles.results, loading && styles.loading)} ref={containerRef}>
        {!results.length && <div className={styles.noResults}>No results found</div>}
        {resultsInPage.map((result, idx) => {
          const isOpened = openedMoreId && openedMoreId === result.url;
          const nextIsOpened = openedMoreId && resultsInPage[idx + 1] && resultsInPage[idx + 1].url === openedMoreId;
          const hasMore = Boolean(result.more && result.more.length);
          const hasMoreLikeThis = Boolean(result.moreLikeTerms && result.moreLikeTerms.length);
          const isManualGroup = result.override && result.expires;
          const isPushedGroup = !result.override && result.expires;
          const isBreaking = result.notification;
          const isNotCovered = !result.coverage;
          const handleToggle = () => handleToggleRelated(result.url, idx + 1);
          const buttonNode =
            (hasMore &&
              (isOpened ? (
                <LessButton handleClick={handleToggle} />
              ) : (
                <MoreButton
                  handleClick={handleToggle}
                  text={desktopInMobileView ? 'More versions' : 'See more versions'}
                  showText={true}
                />
              ))) ||
            undefined;

          return (
            <div
              className={classnames(
                styles.resultWrapper,
                isOpened && styles.openedMore,
                nextIsOpened && styles.nextIsOpened,
                debug && isManualGroup && styles.manualGroup,
                debug && isPushedGroup && styles.pushedGroup,
                debug && isNotCovered && styles.notCovered
              )}
              data-is-opened={isOpened}
              key={idx}
            >
              <ResultItem
                breaking={isBreaking}
                onClick={() => handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', idx + 1)}
                onSiteClick={() =>
                  handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', idx + 1)
                }
                result={result}
                showRelated={debug}
                type="topStory"
                buttonNode={buttonNode}
                isGroupWinner={true}
                desktopInMobileView={desktopInMobileView}
              />
              {isOpened && (
                <div className={styles.moreModal}>
                  <div className={styles.relatedStoriesWrapper}>
                    <ResultItem
                      breaking={isBreaking}
                      onClick={() =>
                        handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', idx + 1)
                      }
                      onSiteClick={() =>
                        handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', idx + 1)
                      }
                      result={result}
                      showRelated={debug}
                      type="topStory"
                      buttonNode={buttonNode}
                      isMainInModal={true}
                      isGroupWinner={true}
                      desktopInMobileView={desktopInMobileView}
                    />
                    {result.more &&
                      result.more.map((moreResult, moreIdx: number) => (
                        <ResultItem
                          key={moreIdx}
                          onClick={() =>
                            handleResultTracking(
                              events.PAGE_VIEW_EVENT,
                              moreResult,
                              'topstory_result_article',
                              idx + 1,
                              moreIdx + 1
                            )
                          }
                          onSiteClick={() =>
                            handleResultTracking(
                              events.PAGE_VIEW_EVENT,
                              moreResult,
                              'topstory_result_site',
                              idx + 1,
                              moreIdx + 1
                            )
                          }
                          result={moreResult}
                          showRelated={debug}
                          type="topStory"
                          isSmall={true}
                          desktopInMobileView={desktopInMobileView}
                        />
                      ))}
                  </div>
                  {hasMoreLikeThis && (
                    <div className={styles.moreLikeThis} onClick={onMoreLikeThis && (() => onMoreLikeThis(result))}>
                      {onMoreLikeThis !== undefined && <span>Search for more on this</span>}
                      {hasMore && isOpened && (
                        <LessButton
                          handleClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleToggle();
                          }}
                          ariaLabel="show related"
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {maxPages > 1 && (
        <div className={styles.pagination}>
          {page > 0 && (
            <button className={classnames(styles.paginationButton, styles.minimiseButton)} onClick={handleClose}>
              <span className={styles.desktopButtonText}>Minimise</span>
              <span className={styles.mobileButtonText}>Minimise top stories</span>
            </button>
          )}
          {page < maxPages - 1 && (
            <button className={classnames(styles.paginationButton, styles.nextButton)} onClick={handleNextPage}>
              <span className={styles.desktopButtonText}>More</span>
              <span className={styles.mobileButtonText}>See more top stories</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
