import React from 'react';
import {render} from 'react-dom';
import URL from 'url-parse';

// tslint:disable-next-line: no-import-side-effect
import './footerPages/footerStyles';
import {App} from './components/App';
import {setApiHost, TopStoriesCategory} from './api';
import {setBaseShareUrl} from './api/social';
import {loadIfNeeded} from './gdpr';
import {initTracking} from './tracking';

const element = document.getElementById('app-root');

const isValidStartTab = (tab: string | undefined) => tab && ['entertainment', 'sport'].includes(tab);

if (element) {
  const parsedUrl = new URL(window.location.href, true);
  let apiHost = element.dataset.apiHost;
  const showTabs = element.dataset.showTabs === 'true';

  if (element.dataset.environment !== 'production' && parsedUrl.query && parsedUrl.query.debugApiHost) {
    apiHost = parsedUrl.query && parsedUrl.query.debugApiHost;
  }

  if (apiHost) {
    setApiHost(apiHost);
  } else {
    throw new Error('No api host set');
  }

  if (element.dataset.absoluteUrl) {
    setBaseShareUrl(element.dataset.absoluteUrl);
  }

  let homeRefreshInterval = element.dataset.homeRefreshInterval
    ? parseInt(element.dataset.homeRefreshInterval, 10)
    : null;

  if (parsedUrl.query && parsedUrl.query.debugHomeRefreshInterval) {
    homeRefreshInterval = parseInt(parsedUrl.query.debugHomeRefreshInterval, 10);
  }

  let startTab: TopStoriesCategory | undefined;

  if (parsedUrl.query && isValidStartTab(parsedUrl.query.startTab)) {
    startTab = parsedUrl.query.startTab as TopStoriesCategory;
  }

  render(
    <App
      startingWithQuery={Boolean(parsedUrl.query && parsedUrl.query.q)}
      showTabs={showTabs}
      startTab={startTab}
      homeRefreshInterval={homeRefreshInterval}
    />,
    element
  );

  loadIfNeeded().catch();

  const {rtaHosts, rtaScript} = element.dataset;

  if (rtaHosts && rtaScript) {
    initTracking({
      hosts: rtaHosts,
      script: rtaScript
    }).catch();
  }
}
