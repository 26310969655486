import axios from 'axios';

import {loadScript} from '../utils';

import './responsive.css';
import './theme.css';

export const GDPR_SCRIPT = 'https://cmp.dmgmediaprivacy.co.uk/2.8.20-newzit.3/iife/mol-ads-cmp.min.js';
export const CCPA_SCRIPT = 'https://cmp.dmgmediaprivacy.co.uk/2.8.18-newzit-ccpa-3/iife/mol-ads-cmp.min.js';
export const LOCATION_FILE = '/locationjson.html';

interface LocationResponse {
  Country: string;
  CITY: string;
  INEU: boolean;
  REGION_CODE: string;
}

interface WindowWithCriteria extends Window {
  PageCriteria?: {
    geo?: string;
    region?: string;
  };
}

export const loadIfNeeded = async ({render = true} = {}) => {
  try {
    const {data} = await axios.get<LocationResponse>(LOCATION_FILE, {responseType: 'json'});
    const isCCPA = data.Country === 'US';

    (window as WindowWithCriteria).PageCriteria = {
      geo: data.Country,
      region: data.REGION_CODE
    };

    if (!data.INEU && !isCCPA) {
      return false;
    }

    const params: {[key: string]: string} = {
      id: 'mol-ads-cmp'
    };

    if (render) {
      params['data-render'] = 'true';
    }

    await loadScript(isCCPA ? CCPA_SCRIPT : GDPR_SCRIPT, params);

    return true;
  } catch (error) {
    // Do not load
    return false;
  }
};
