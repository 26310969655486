import React from 'react';
import classnames from 'classnames';

import {trackEvent, events} from '../../tracking';
import {followFacebook, followTwitter, shareFacebook, shareTwitter} from '../../api/social';
import {GeoCountry} from '../../api';

import styles from './styles.css';

export interface SocialButtonsProps {
  mode: 'inline' | 'standalone';
  sourceGeo: GeoCountry;
}

export const SocialButtons = ({mode, sourceGeo}: SocialButtonsProps) => {
  const handleFollowFacebook = () => {
    trackEvent(events.SOCIAL_FOLLOW_FACEBOOK, {geo: sourceGeo});
    followFacebook(sourceGeo);
  };
  const handleFollowTwitter = () => {
    trackEvent(events.SOCIAL_FOLLOW_TWITTER, {geo: sourceGeo});
    followTwitter(sourceGeo);
  };
  const handleShareFacebook = () => {
    trackEvent(events.SOCIAL_SHARE_FACEBOOK, {geo: sourceGeo});
    shareFacebook();
  };
  const handleShareTwitter = () => {
    trackEvent(events.SOCIAL_SHARE_TWITTER, {geo: sourceGeo});
    shareTwitter();
  };

  return (
    <div className={styles[mode]}>
      <div className={styles.follow}>
        <div className={styles.label}>Follow us</div>
        <button
          aria-label="follow on twitter"
          className={classnames(styles.button, styles.twitter)}
          onClick={handleFollowTwitter}
        />
        <button
          aria-label="follow on facebook"
          className={classnames(styles.button, styles.facebook)}
          onClick={handleFollowFacebook}
        />
      </div>
      <div className={styles.share}>
        <div className={styles.label}>Share</div>
        <button
          aria-label="share on twitter"
          className={classnames(styles.button, styles.twitter)}
          onClick={handleShareTwitter}
        />
        <button
          aria-label="share on facebook"
          className={classnames(styles.button, styles.facebook)}
          onClick={handleShareFacebook}
        />
      </div>
    </div>
  );
};
