import {getExternalAppState} from './externalAppState';

export interface AdSlotsWindow extends Window {
  adPositions?: {[key: string]: string | undefined};
}

export const mountAd = (slotId: string, position: string) => {
  window.postMessage(
    {
      data: {
        appState: getExternalAppState() || 'home',
        position,
        slotId
      },
      scope: 'adSlotMount'
    },
    window.location.href
  );

  const win = window as AdSlotsWindow;

  if (!win.adPositions) {
    win.adPositions = {};
  }

  win.adPositions[position] = slotId;
};

export const unmountAd = (slotId: string, position: string) => {
  window.postMessage(
    {
      data: {
        appState: getExternalAppState() || 'home',
        position,
        slotId
      },
      scope: 'adSlotUnmount'
    },
    window.location.href
  );

  const win = window as AdSlotsWindow;

  if (win.adPositions && win.adPositions[position]) {
    win.adPositions[position] = undefined;
  }
};
