let apiHost: string;

export type GeoCountry = 'gb' | 'us';
export const GEO_COUNTRIES: GeoCountry[] = ['gb', 'us'];
export type TopStoriesCategory = 'entertainment' | 'news' | 'sport';

export const getCategoryKeyByTabAndGeo = (category: TopStoriesCategory, sourceGeo: GeoCountry) =>
  `${category}${sourceGeo.toUpperCase()}`;

export const setApiHost = (host: string) => {
  apiHost = host;
};

export const apiPath = (path: string) => `${apiHost}${path}`;
