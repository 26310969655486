import React, {useEffect} from 'react';

import {mountAd, unmountAd} from '../../api/ads';

import styles from './styles.css';

interface AdSlotProps {
  position: string;
  slotId: string;
  slotType: 'mpu' | 'billboard';
}

export const AdSlot = ({position, slotId, slotType}: AdSlotProps) => {
  useEffect(() => {
    mountAd(slotId, position);

    return () => {
      unmountAd(slotId, position);
    };
  }, []);

  return <div className={styles[slotType]} id={slotId}></div>;
};
