import React, {CSSProperties} from 'react';

export const IconMobile = ({className, style}: {className?: string; style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    className={className}
    style={style}
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M10 0v16H0V0h10zM5 13.44c-.552 0-1 .43-1 .96s.448.96 1 .96 1-.43 1-.96-.448-.96-1-.96zM9.167 1.12H.833v12h8.334v-12z"
    />
  </svg>
);
